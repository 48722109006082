.stepper-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .stepper-item {
    position: relative;
    flex: 1;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
  }
  
  .stepper-item::before,
  .stepper-item::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #e0e0e0;
  }
  
  .stepper-item::before {
    left: -50%;
    z-index: 1;
  }
  
  .stepper-item::after {
    right: -50%;
    z-index: 1;
  }
  
  .stepper-item:first-child::before,
  .stepper-item:last-child::after {
    display: none;
  }
  
  .step-counter {
    position: relative;
    z-index: 2;
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    background: #e0e0e0;
    border-radius: 50%;
    color: #fff;
  }
  
  .step-name {
    position: relative;
    z-index: 2;
    margin-top: 10px;
    font-size: 16px;
  }
  
  .stepper-item.active .step-counter {
    background: #007bff;
  }
  
  .stepper-item.active .step-name {
    font-weight: bold;
  }
  